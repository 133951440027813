import { About } from "./components/About";
import { CallToAction } from "./components/CallToAction";
import { Contacts } from "./components/Contacts";
import { Hero } from "./components/Hero";
import { Footer } from "./components/layout/Footer";
import { Header } from "./components/layout/Header";
import { Services } from "./components/Services";

function App() {
  return (
    <div className="bg-gradient-diagonal">
      <Header />
      <div>
        <Hero />
        <Services />
        <CallToAction />
        <About />
        <Contacts />
      </div>
      <Footer />
    </div>
  );
}

export default App;

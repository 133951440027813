import About1 from "../assets/about1.jpg";
import About2 from "../assets/about2.jpg";
import About3 from "../assets/about3.jpg";
import About4 from "../assets/about4.jpg";
import Testimonial1 from "../assets/testimonial1.mp4";
import { FaUsers, FaBrain, FaEnvelope, FaPhone } from "react-icons/fa";
import { GiSoccerBall } from "react-icons/gi";
import { TbSoccerField } from "react-icons/tb";
import { SiTiktok } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";

const content = {
  hero: {
    title: "Ta Din Fotboll Till Nästa Nivå",
    subtitle: "Elitträning med Nordic Pro",
    description:
      "Vill du bli en bättre fotbollsspelare? Vår träning hjälper dig att bli snabbare, starkare och smartare på planen.",
    buttonText: "Kom Igång Idag",
    buttonLink: "callToAction",
  },
  about: {
    title: "Erfarenhet Och Passion",
    subtitle: "Vi Vet Vad Som Krävs För Att Lyckas",
    description: `Jag har spelat professionell fotboll i Italien och är nu tränare på IK Sirius. Min passion är att hjälpa dig att bli bäst på planen. Med fokus på fysisk styrka, tekniska färdigheter och taktisk förståelse ser jag till att du utvecklas på alla områden. Med min erfarenhet får du träning som funkar.`,
    images: [About1, About2, About3, About4],
    button: "Kontakta Mig",
  },
  services: {
    title: "Våra Tjänster",
    subtitle: "Träning Som Gör Skillnad",
    description:
      "Hos Nordic Pro får du träning som passar just dig. Vi hjälper dig att utvecklas som spelare, oavsett om du tränar ensam eller med laget.",
    list: [
      {
        title: "Individuell Träning",
        description:
          "Personlig träning för att förbättra dina styrkor och jobba på dina svagheter. Bli bäst på det du gör.",
        icon: <GiSoccerBall />,
      },
      {
        title: "Gruppträning",
        description:
          "Träna tillsammans med andra och utveckla dina färdigheter samtidigt som du bygger laganda.",
        icon: <FaUsers />,
      },
      {
        title: "Lagträning",
        description:
          "Få hela laget att prestera bättre med fokus på strategi, kommunikation och samarbete.",
        icon: <TbSoccerField />,
      },
      {
        title: "Mental Träning",
        description:
          "Lär dig hantera press och bli starkare mentalt. Bygg självförtroende och håll fokus i varje match.",
        icon: <FaBrain />,
      },
    ],
  },
  testimonials: {
    title: "Vad Våra Spelare Säger",
    subtitle: "Riktiga Resultat, Riktiga Människor",
    description:
      "Lyssna på vad våra spelare och tränare har att säga om vår träning. Deras framgångar visar att vår metod fungerar.",
    videos: [
      {
        url: Testimonial1,
        thumbnail: About1,
        name: "John Doe",
        role: "Proffsspelare",
      },
      {
        url: Testimonial1,
        thumbnail: About2,
        name: "Jane Smith",
        role: "Fotbollstränare",
      },
      {
        url: Testimonial1,
        thumbnail: About3,
        name: "Emily Johnson",
        role: "Personlig Tränare",
      },
      {
        url: Testimonial1,
        thumbnail: About4,
        name: "Michael Brown",
        role: "Lagledare",
      },
    ],
  },
  callToAction: {
    title: "Kontakta Oss",
    subtitle: "Boka En Träning Eller Ställ En Fråga",
    description: "Vill du veta mer om vår träning eller boka ett pass?",
    services: [
      "Individuell Träning",
      "Gruppträning",
      "Lagträning",
      "Mental Träning",
    ],
    buttonText: "Skicka",
  },
  contacts: {
    title: "Kom I Kontakt",
    contactInfo: [
      {
        icon: <FaEnvelope className="text-3xl text-primary-blue" />,
        title: "E-post",
        details: "coach.nordicpro@gmail.se",
        link: "mailto:coach.nordicpro@gmail.se",
      },
      {
        icon: <FaPhone className="text-3xl text-primary-blue" />,
        title: "Telefon",
        details: "0737264701",
        link: "tel:0737264701",
      },
      {
        icon: <SiTiktok className="text-3xl text-primary-blue" />,
        title: "TikTok",
        details: "@_nordicpro",
        link: "https://www.tiktok.com/@_nordicpro",
      },
      {
        icon: <AiFillInstagram className="text-3xl text-primary-blue" />,
        title: "Instagram",
        details: "@_nordicpro",
        link: "https://www.instagram.com/_nordicpro",
      },
    ],
  },
  footer: {
    title: "Håll Kontakten",
    description:
      "Följ oss på sociala medier för de senaste uppdateringarna och nyheterna.",
    address: "Uppsala, Sverige",
    phone: {
      icon: <FaPhone />,
      title: "Telefon",
      details: "0737264701",
      link: "tel:0737264701",
    },
    email: {
      icon: <FaEnvelope />,
      title: "E-post",
      details: "coach.nordicpro@gmail.se",
      link: "mailto:coach.nordicpro@gmail.se",
    },
    links: [
      { label: "Hem", section: "home" },
      { label: "Om Oss", section: "about" },
      { label: "Tjänster", section: "services" },
      { label: "Vittnesmål", section: "testimonials" },
      { label: "Kontakt", section: "contact" },
    ],
    social: [
      {
        icon: <SiTiktok className="" />,
        title: "TikTok",
        details: "@n_ordicpro",
        link: "https://www.tiktok.com/@_nordicpro",
      },
      {
        icon: <AiFillInstagram className="" />,
        title: "Instagram",
        details: "@_nordicpro",
        link: "https://www.instagram.com/_nordicpro",
      },
    ],
  },
};

export default content;

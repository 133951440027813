import React from "react";
import content from "../../content/content";
import { Link } from "react-scroll";
import logo from "../../assets/logo2.png";

export const Footer = () => {
  const { footer } = content; // Destructure footer information from content

  return (
    <footer className=" bg-gradient-diagonal-footer text-white py-8 flex md:justify-center">
      <div className="container text-center md:w-10/12">
        <div className="flex flex-col-reverse md:flex-row justify-center ">
          <Link
            to="home"
            smooth={true}
            duration={500}
            className=" cursor-pointer  w-full md:w-1/4 flex justify-center"
          >
            <img src={logo} alt="logo" className="h-48 w-48" />
          </Link>
          <div className="mb-6 md:mb-0 w-full md:w-1/4 ">
            <Link
              to="home"
              smooth={true}
              duration={500}
              className=" cursor-pointer text-2xl font-headline text-ice-blue "
            >
              <p>NordicPro</p>
            </Link>
            <address className="text-ice-blue not-italic flex flex-col justify-center items-center  mt-2">
              <p className="mb-2">{footer.address}</p>
              <a
                href={footer.phone.link}
                className="text-ice-blue-70  hover:text-ice-blue  flex items-center gap-2 mb-2"
              >
                {footer.phone.icon}
                {footer.phone.details}
              </a>
              <a
                href={footer.email.link}
                className="text-ice-blue-70  hover:text-ice-blue flex items-center gap-2 mb-2"
              >
                {footer.email.icon}
                {footer.email.details}
              </a>
            </address>
          </div>
          <div className="mb-6 md:mb-0 w-full md:w-1/4">
            <h3 className="text-xl font-subheading mb-2 text-ice-blue">
              Sidkarta
            </h3>
            <ul className="list-none">
              {footer.links.map((link, index) => (
                <li key={index} className="mb-2">
                  <Link
                    smooth={true}
                    duration={500}
                    to={link.section}
                    className="cursor-pointer text-ice-blue-70 hover:text-ice-blue"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-6 md:mb-0 w-full md:w-1/4 ">
            <h3 className="text-xl font-subheading  mb-2 text-ice-blue">
              Följ oss
            </h3>
            <div className="flex justify-center  gap-4">
              {footer.social.map((s, index) => (
                <a
                  key={index}
                  href={s.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-ice-blue-70 hover:text-ice-blue text-2xl "
                >
                  <div> {s.icon}</div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <p className="text-ice-blue-70 text-sm">
            &copy; {new Date().getFullYear()} Nordic Pro. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

import React from "react";
import content from "../content/content";
import contactImage from "../assets/contacts.jpg";
import { motion } from "framer-motion"; // Import framer-motion

export const Contacts = () => {
  const { contactInfo } = content.contacts; // Destructure contactInfo from content

  const socialVariants = {
    hidden: { scale: 0.1, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <div id="contact" className="pt-32"></div>
      <div
        className=" h-auto relative md:h-[45vh] bg-cover bg-center "
        style={{ backgroundImage: `url(${contactImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={socialVariants}
          viewport={{ once: true, amount: 0.1 }}
          className="relative z-10 flex flex-col items-center justify-center h-full gap-10  py-14 md:py-0 text-white"
        >
          <h2 className="text-3xl font-headline text-ice-blue">
            {content.contacts.title}
          </h2>
          <div className="flex flex-col justify-center md:flex-row  md:space-x-20 space-y-16 md:space-y-0">
            {contactInfo.map((contact, index) => (
              <a
                key={index}
                href={contact.link}
                target="_blank"
                rel="noopener noreferrer"
                className="relative flex gap-2 flex-col items-center transition-all duration-300 group"
              >
                <div className=" bg-ice-blue md:bg-ice-blue-70 group-hover:bg-ice-blue p-4 rounded-full shadow-lg">
                  <div className="text-primary-blue text-4xl">
                    {contact.icon}
                  </div>
                </div>
                <div className=" text-center flex items-center justify-center gap-2 text-ice-blue md:text-ice-blue-70 group-hover:text-ice-blue">
                  <p className="text-xl font-subheading ">{contact.details}</p>
                </div>
              </a>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
};

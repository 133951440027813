import React, { useState } from "react";
import heroImage from "../assets/hero.jpg";
import { Link } from "react-scroll";
import content from "../content/content";
import { motion } from "framer-motion";
import { FaPhoneAlt } from "react-icons/fa";

export const Hero = () => {
  const [hover, setHover] = useState(false);
  const { title, subtitle, buttonText, buttonLink } = content.hero;

  // Define animation variants
  const textVariants = {
    hidden: { scale: 0.1, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div
      id="home"
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={textVariants}
        className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white"
      >
        <h1
          className="leading-snug text-5xl md:text-6xl font-headline mb-4 mx-10"
          variants={textVariants}
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
        >
          {title}
        </h1>
        <p
          className="text-2xl md:text-3xl font-subheading mx-2"
          variants={textVariants}
        >
          {subtitle}
        </p>
        <button
          className="mt-6 inline-block border-2 border-transparent bg-ice-blue text-secondary-blue font-semibold py-3 px-6 rounded-full shadow-md transition-all duration-300 transform hover:scale-105"
          variants={textVariants}
        >
          <Link
            smooth={true}
            duration={1000}
            to={buttonLink}
            className="relative z-10"
          >
            {buttonText}
          </Link>
        </button>
      </motion.div>

      {/* Phone Icon and Message */}
      <div
        className="fixed bottom-4 right-4 z-40 flex flex-row items-center gap-1"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className={`hidden lg:block font-subheading text-sm transition-transform duration-300 ease-in-out border border-primary-blue bg-white text-primary-blue py-2 px-4 rounded-lg shadow-lg ${
            hover ? "translate-x-0 opacity-100" : "translate-x-6 opacity-0"
          }`}
        >
          Ringa mig
        </div>
        <a
          href="tel:0737264701" // Replace with your phone number
          className="bg-white text-primary-blue p-4 rounded-full shadow-lg border border-primary-blue hover:bg-gray-100 transition-colors duration-300 flex items-center justify-center"
        >
          <FaPhoneAlt size={24} />
        </a>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import HeaderLinks from "./HeaderLinks";
import { Link } from "react-scroll";
import logo from "../../assets/logo2.png";

export const Header = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    const sections = ["home", "about", "services", "testimonials", "contact"];
    const scrollY = window.scrollY;
    const offset = window.innerHeight / 2 - 350; // Custom offset

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (
          scrollY >= offsetTop - offset &&
          scrollY < offsetTop + offsetHeight - offset
        ) {
          setActiveSection(section);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to handle menu item clicks
  const handleMenuClick = (section) => {
    setActiveSection(section);
    setIsMenuOpen(false); // Close the menu after selection
  };

  return (
    <header className="fixed mx-auto w-full z-50">
      <nav className="mt-4 rounded-3xl w-11/12 md:w-10/12 container mx-auto flex items-center justify-between p-4 md:px-8 bg-black bg-opacity-50 backdrop-blur-lg shadow-2xl z-50">
        <Link
          to="home"
          smooth={true}
          duration={500}
          className="cursor-pointer text-3xl font-headline text-ice-blue flex items-center gap-2"
        >
          <img src={logo} className="h-10 w-10" alt="Logo" />
          <p>NordicPro</p>
        </Link>
        <div className="hidden md:flex space-x-6">
          <ul className="flex space-x-6">
            <HeaderLinks
              activeSection={activeSection}
              onClick={handleMenuClick}
            />
          </ul>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white text-3xl">
            <GiHamburgerMenu />
          </button>
        </div>
      </nav>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-black flex flex-col items-center justify-center z-50 lg:hidden">
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-white text-3xl"
          >
            <MdClose />
          </button>
          <ul className="space-y-8 flex flex-col items-center justify-center">
            <HeaderLinks
              activeSection={activeSection}
              onClick={handleMenuClick}
            />
          </ul>
        </div>
      )}
    </header>
  );
};

import React, { useState } from "react";
import content from "../content/content";
import emailjs from "emailjs-com";
import { motion } from "framer-motion";
import image from "../assets/form.jpg";
import { FaCheck } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im"; // Import a spinner icon

export const CallToAction = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    team: "",
    service: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // State to handle loading effect
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state

  const { services } = content;

  const container1Variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) newErrors.name = "Namn krävs.";
    if (!formData.email) {
      newErrors.email = "E-post krävs.";
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Ogiltigt e-postformat.";
    }
    if (!formData.service) newErrors.service = "Tjänsteval krävs.";
    if (!formData.message) newErrors.message = "Meddelande krävs.";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true); // Start loading
    setIsModalOpen(true); // Show modal

    // Using EmailJS to send the email
    emailjs
      .sendForm(
        "service_zzjemn8",
        "template_635okgk",
        e.target,
        "NPt7mNcSpels1zThI"
      )
      .then(
        (result) => {
          console.log("Email sent:", result.text);
          setLoading(false); // Stop loading
          setSubmitted(true); // Mark as submitted
          setFormData({
            name: "",
            email: "",
            role: "",
            team: "",
            service: "",
            message: "",
          }); // Reset form fields
        },
        (error) => {
          console.log("Email error:", error.text);
          setLoading(false); // Stop loading even on error
          setIsModalOpen(false); // Close modal on error
        }
      );
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
    setSubmitted(false); // Reset the submission state for future form submissions
  };

  return (
    <div id="callToAction" className="section flex items-center justify-center">
      <div className="container">
        <motion.div
          variants={container1Variants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          className="relative h-auto bg-cover bg-center p-8 rounded-3xl shadow-lg flex flex-col items-center justify-center"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="absolute inset-0 bg-black opacity-30 rounded-3xl"></div>
          <div className="relative z-10 text-center flex flex-col items-center">
            <h2 className="text-3xl lg:text-4xl font-headline mb-4 text-ice-blue">
              {content.callToAction.title}
            </h2>
            <p className="text-base lg:text-xl font-subheading mb-6 text-white">
              {content.callToAction.subtitle}
            </p>
            <p className="text-sm lg:text-base font-body mb-6 text-white md:w-1/2 text-center">
              {content.callToAction.description}
            </p>

            <form
              onSubmit={handleSubmit}
              className="space-y-4 w-full md:w-1/2 mx-auto text-secondary-blue"
            >
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Ditt namn"
                required
                className={`w-full px-4 py-2 bg-ice-blue rounded-lg ${
                  errors.name ? "border-red-500" : ""
                }`}
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Din e-post"
                required
                className={`w-full px-4 py-2 bg-ice-blue rounded-lg ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
              <input
                type="text"
                name="team"
                value={formData.team}
                onChange={handleChange}
                placeholder="Ditt team (valfritt)"
                className="w-full px-4 py-2 bg-ice-blue rounded-lg"
              />
              <input
                type="text"
                name="role"
                value={formData.role}
                onChange={handleChange}
                placeholder="Din roll (valfritt)"
                className="w-full px-4 py-2 bg-ice-blue rounded-lg"
              />
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
                className={`w-full px-4 py-2 bg-ice-blue rounded-lg ${
                  errors.service ? "border-red-500" : ""
                }`}
              >
                <option value="" disabled>
                  Välj en tjänst
                </option>
                {services.list.map((service) => (
                  <option key={service.title} value={service.title}>
                    {service.title}
                  </option>
                ))}
                <option key="other" value="Other">
                  Annat
                </option>
              </select>
              {errors.service && (
                <p className="text-red-500 text-sm">{errors.service}</p>
              )}

              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Ditt meddelande"
                rows="4"
                required
                className={`w-full px-4 py-2 bg-ice-blue rounded-lg ${
                  errors.message ? "border-red-500" : ""
                }`}
              />
              {errors.message && (
                <p className="text-red-500 text-sm">{errors.message}</p>
              )}
              <button
                type="submit"
                className="cursor-pointer inline-block bg-primary-blue hover:bg-secondary-blue text-white py-2 px-4 rounded-full shadow-md transition-colors duration-300"
              >
                {content.callToAction.buttonText}
              </button>
            </form>
          </div>
        </motion.div>

        {/* Modal */}
        {isModalOpen && (
          <div className="z-20 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-80 text-center shadow-lg">
              {loading ? (
                <>
                  <ImSpinner2 className="animate-spin text-primary-blue h-10 w-10 mx-auto mb-4" />
                  <p className="text-xl text-primary-blue">
                    Skickar ditt meddelande...
                  </p>
                </>
              ) : submitted ? (
                <>
                  <FaCheck className="text-green-500 h-10 w-10 mx-auto mb-4" />
                  <p className="text-xl text-green-500">
                    Tack för att du hörde av dig! Vi återkommer till dig snart.
                  </p>
                  <button
                    onClick={closeModal}
                    className="mt-4 bg-primary-blue text-white py-2 px-4 rounded-full"
                  >
                    Stäng
                  </button>
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

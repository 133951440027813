import React from "react";
import content from "../content/content";
import { motion } from "framer-motion"; // Import framer-motion

export const About = () => {
  const { title, description, images, subtitle } = content.about;

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <div
      id="about"
      className="section flex flex-col justify-center items-center "
    >
      <div className="w-full">
        <motion.div
          className="text-center mb-12 bg-white p-8 rounded-3xl shadow-md lg:shadow-xl"
          initial="hidden"
          whileInView="visible"
          variants={containerVariants}
          viewport={{ once: true, amount: 0.1 }}
        >
          <h2 className="text-3xl lg:text-4xl font-headline text-primary-blue mb-4">
            {title}
          </h2>
          <p className="text-base lg:text-xl font-subheading text-gray-700 mb-6">
            {subtitle}
          </p>
          <p className="text-sm lg:text-base font-body text-gray-500 mb-6">
            {description}
          </p>
        </motion.div>
        <motion.div
          className="flex flex-col md:flex-row md:space-x-8 space-y-6 md:space-y-0"
          initial="hidden"
          whileInView="visible"
          variants={containerVariants}
          viewport={{ once: true, amount: 0.1 }}
        >
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="w-full h-80 rounded-3xl overflow-hidden shadow-md"
              variants={imageVariants}
            >
              <div className="relative w-full h-full">
                <img
                  src={image}
                  alt={`Coach ${index + 1}`}
                  className="w-full h-full object-cover object-center transform transition-transform duration-300 hover:scale-110"
                />
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

import React from "react";
import { motion } from "framer-motion"; // Import framer-motion
import content from "../content/content";

export const Services = () => {
  const { services } = content;

  const container1Variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const container2Variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <div id="services" className="section flex items-center">
      <div className="container">
        <motion.div
          variants={container1Variants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          className="text-center mb-16 bg-white p-8 rounded-3xl shadow-lg"
        >
          <h2 className="text-4xl font-headline text-primary-blue mb-4">
            {services.title}
          </h2>
          <p className="text-base lg:text-xl font-subheading text-gray-700 mb-6">
            {services.subtitle}
          </p>
          <p className="text-sm lg:text-base font-body text-gray-500">
            {services.description}
          </p>
        </motion.div>
        <motion.div
          className="flex flex-col md:flex-row md:space-x-8 space-y-12 md:space-y-0"
          initial="hidden"
          whileInView="visible"
          variants={container2Variants}
          viewport={{ once: true, amount: 0.1 }}
        >
          {services.list.map((service, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.3 },
              }}
              className="cursor-default w-full relative flex flex-col items-center bg-gradient-to-br from-primary-blue to-secondary-blue p-6 rounded-3xl shadow-xl"
            >
              <div className="absolute -top-8 bg-ice-blue p-4 rounded-full shadow-lg">
                <div className="text-primary-blue text-5xl">{service.icon}</div>
              </div>
              <div className="mt-16 text-center">
                <h3 className="text-2xl font-bold text-ice-blue mb-2">
                  {service.title}
                </h3>
                <p className="text-white">{service.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

import React from "react";
import { Link } from "react-scroll";

const HeaderLinks = ({ activeSection, onClick }) => {
  const sections = [
    { ref: "home", name: "Hem" },
    { ref: "services", name: "Tjänster" },
    // { ref: "testimonials", name: "Vittnesmål" },
    { ref: "about", name: "Om Oss" },
    { ref: "contact", name: "Kontakt" },
  ];

  return (
    <>
      {sections.map((section) => (
        <li key={section.ref}>
          <Link
            to={section.ref}
            smooth={true}
            duration={500}
            onClick={onClick}
            className={`cursor-pointer font-bold text-3xl md:text-base hover:text-ice-blue transition-opacity duration-300 ${
              activeSection === section.name
                ? "text-ice-blue"
                : "text-ice-blue-70"
            }`}
          >
            {section.name}
          </Link>
        </li>
      ))}
    </>
  );
};

export default HeaderLinks;
